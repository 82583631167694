<template>
  <div class="app-modal modal fade hide" id="loginModal" data-backdrop="static" data-keyboard="false"  tabindex="-1">
    <div class="modal-dialog modal-dialog-centered overflow-hidden">
      <div class="modal-content  position-relative">
        
        <login-view v-if="isLogin" v-on:forgot-password="forgotPassword" :isLoading="isLoading" />

        <forgot-password-view v-if="isForgotPassword" v-on:login="login"
          v-on:forgotPasswordMethod="forgotPasswordMethod" :isLoading="isLoading" v-on:hide="hide" />

        <otp-view v-if="isOtp" v-on:verifyOtp="verifyOtp" :errorMessage="errorMessage" :number="phoneNumber" :otpId="otpId" 
        v-on:forgotPasswordMethod="forgotPasswordMethod"  :loader="isLoading"
        />

        <new-password-view v-if="isNewPassword" v-on:newPasswordMethod="newPasswordMethod" :number="phoneNumber" :isLoading="isLoading" />

        <success-view v-if="isSuccess" v-on:login="login" />

      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import LoginView from "./LoginView.vue";
import ForgotPasswordView from "./ForgotPasswordView.vue";
import OtpView from "./OtpView.vue";
import NewPasswordView from "./NewPasswordView.vue";
import SuccessView from "./SuccessView.vue";
import api from "@/api/account.api";

export default {
  components: { LoginView, ForgotPasswordView, OtpView, NewPasswordView, SuccessView },
  data: () => ({
    isLogin: true,
    isForgotPassword: false,
    isOtp: false,
    isNewPassword: false,
    isSuccess: false,
    modal: null,
    otpId: null,
    phoneNumber: null,
    errorMessage: null,
    isLoading: false,
    state:0
  }),
  mounted() {
    this.modal = new Modal(document.getElementById("loginModal"));
  },
  methods: {
    clear() {
      this.isLogin = true;
      this.isForgotPassword = false;
      this.isOtp = false;
      this.isNewPassword = false,
      this.isSuccess = false
    },

    show() {
      this.clear();
      console.log("this.isLogin",this.isLogin);
      console.log("this.isForgotPassword",this.isForgotPassword);
      console.log("this.isOtp",this.isOtp);
      console.log("this.isNewPassword",this.isNewPassword);
      console.log("this.isSuccess",this.isSuccess);
      console.log("=======================")


      this.modal.show();
    },

    hide() {
      // this.isLogin = false;
      // this.isForgotPassword = false;
      // this.isOtp = false;
      // this.isNewPassword = false
      // this.isSuccess = false
    },

    forgotPassword() {
      this.isLogin = false;
      this.isForgotPassword = true;
      this.isOtp = false;
      this.isNewPassword = false
      this.isSuccess = false
    },

    login() {
      this.isLogin = true;
      this.isForgotPassword = false;
      this.isOtp = false;
      this.isNewPassword = false,
      this.isSuccess = false
    },

    otp() {
      this.isLogin = false;
      this.isForgotPassword = false;
      this.isOtp = true;
      this.isNewPassword = false,
        this.isSuccess = false
    },

    newPassword() {
      this.isLogin = false;
      this.isForgotPassword = false;
      this.isOtp = false;
      this.isNewPassword = true,
      this.isSuccess = false
    },

    successResult() {
      this.isLogin = false;
      this.isForgotPassword = false;
      this.isOtp = false;
      this.isNewPassword = false,
      this.isSuccess = true
    },


    forgotPasswordMethod(phoneNumber) {
      this.isLoading = true;
      this.phoneNumber = phoneNumber;
      api.otpGenerate(phoneNumber)
        .then((data) => {
          this.otpId = data;
          this.otp();
        })
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },

    verifyOtp(otpId, phoneNumber, otpCode) {
      this.isLoading = true;
      api.otpVerify(otpId, phoneNumber, otpCode)
        .then((response) => {
          this.newPassword();
        })
        .catch((error) => {
          this.errorMessage = "Daxil etdiyiniz kod yanlışdır."
        })
        .finally(() => (this.isLoading = false));
    },

    newPasswordMethod(phoneNumber, newPassword) {
      this.isLoading = true;
      api.resetPassword(phoneNumber, newPassword)
        .then(
          () =>
            this.successResult()
        )
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
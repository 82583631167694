<template>
  <main>
    <navbar />
    <router-view />
    

    <login-modal ref="loginModal" />
    <app-share-modal />
  </main>
  <footer-section />
</template>

<script>
import Navbar from "./shared/Navbar.vue";
import FooterSection from "./shared/Footer.vue";
import AppShareModal from "./shared/AppShareModal.vue";
import LoginModal from "./account/LoginModal.vue";

import "swiper/modules/navigation/navigation.min.css";

export default {
  components: { Navbar, FooterSection, AppShareModal, LoginModal },
  methods: {
    showAppShare() {
      return this.$isMobile();
    },
  },
  created() {
    this.emitter.on("showLoginModal", () => {
      this.$refs["loginModal"].show();
    });
  }
};
</script>
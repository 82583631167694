<template>
  <div class="app-modal-body widget-tracker widget-tracker-active text-center" id="password-widget-tracker">
    <img src="@/assets/images/link-success.svg" alt="success" />
    <div class="app-modal-header border-0 mt-3">
      <p class="modal-title h2 text-main fw-bold">Yeni şifrə təsdiq edildi!</p>
      <p class="modal-subtitle mt-0">
        Şifrənizi daxil edərək hesabınıza
        keçid edə bilərsiniz.
      </p>
      <button type="button" class="app-modal-close" data-bs-dismiss="modal"></button>
    </div>

    <button @click.prevent="login" type="submit"
      class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100 modal-btn">
      Daxil ol
    </button>

  </div>
</template>

<script>
export default {
  methods: {
    login() {
      this.$emit("login");
    },
  },
};
</script>
  
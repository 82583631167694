<template>
  <div class="container">
    <div class="row justify-content-between px-3">
      <input v-for="(index) in 4" :key="index" :ref="`otpInput${index}`" v-model="otp[index - 1]"
        @input="handleInput(index)" @keydown="handleKeyDown($event, index)" maxlength="1" type="text"
        class=" otp-input" />
    </div>
  </div>
</template>
  
<script>
export default {
  props:["otp"],
  methods: {
    handleInput(index) {
      if (this.otp[index - 1].length === 1 && index < 4) {
        this.$refs[`otpInput${index + 1}`][0].focus();
      }
    },
    handleKeyDown(event, index) {
      if (event.key === 'Backspace' && index > 0 && !this.otp[index - 1]) {
        this.$refs[`otpInput${index - 1}`][0].focus();
      }
    },

   
  }
};
</script>
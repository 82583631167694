<template>
  <div
    class="app-modal modal fade app-modal-mobile app-share-modal"
    id="appShareModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered overflow-hidden">
      <div class="modal-content  bg-blue">
        <div class="app-mobile-modal-body bg-blue mt-2">
          <div class="app-mobile-modal-header d-flex justify-content-center">
            <img
              src="@/assets/images/mobapp.svg"
              class="app-mobile-modal-img position-absolute"
            />
            <button
              type="button"
              class="app-mobile-modal-close text-end"
              data-bs-dismiss="modal"
              @click="hide"
            ></button>
          </div>
        </div>

        <div class="app-modal-mobile-footer pt-5 d-block text-center">
          <p class="h5 text-white fw-bold mb-3">
            Tətbiq isə daha sürətli və rahatdir!
          </p>
          <p>
            <a class="btn bg-white text-blue px-5 py-2 font-size-default">
              Tətbiqi yüklə
            </a>
          </p>
          <p>
            <a
              class="btn text-white px-5 py-2"
              data-bs-dismiss="modal"
              @click.prevent="hide"
            >
              Başqa vaxt
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  data: () => ({
    modal: null,
  }),
  mounted() {
    this.modal = new Modal(document.getElementById("appShareModal"));

    if (window.innerWidth < 576) {
      if (!localStorage.getItem("app-share-closed")) {
        this.modal.show();
      }
    }
  },
  methods: {
    show() {
      this.modal.show();
    },

    hide() {
      this.modal.hide();
      localStorage.setItem("app-share-closed", true);
    },
  },
};
</script>
<template>
  <div class="widget-tracker widget-tracker-active" id="password-widget-tracker">
    <div class="app-modal-header border-0">
      <p class="modal-title h2 text-main fw-bold">Yeni şifrə</p>
      <p class="modal-subtitle mt-0">
        Yeni şifrəni daxil edin və təsdiqləndikdən
        sonra hesabınıza keçid edin.
      </p>
      <button type="button" class="app-modal-close" data-bs-dismiss="modal"></button>
    </div>
    <form @submit.prevent="submitHandler">

      <div class="form-group mt-3 position-relative" :class="{ error: v$.form.newPassword.$errors.length }">
        <password-input title="Yeni şifrə" v-model:inputData="form.newPassword" />
      </div>

      <div class="form-group mt-4 position-relative" :class="{ error: v$.form.confirmPassword.$errors.length }">
        <password-input title="Yeni şifrəni təkrarlayın" v-model:inputData="form.confirmPassword" />
      </div>

      <p class="otp-error text-right mt-4 ms-1">{{ errorMessage }}</p>

      <button :disabled="isLoading" type="submit" class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100 modal-btn">
        <span class="spinner-border spinner-border-sm" v-if="isLoading" />
        Təsdiq edin
      </button>

    </form>
  </div>
</template>
  
<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const initialValue = {
  newPassword: null,
  confirmPassword: null,
};

const validations = {
  newPassword: { required },
  confirmPassword: { required },
};

export default {
  props: ["number","isLoading"],
  directives: { maska },
  data: () => ({
    form: { ...initialValue },
    errorMessage:null
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async submitHandler() {
      const result =await this.v$.$validate();
      const number = this.number;
      if (result) {
        if(this.form.newPassword==this.form.confirmPassword){
          this.$emit("newPasswordMethod", number, this.form.newPassword);
        }
        else{
          this.errorMessage="Daxil edilən şifrələr uyğun deyil."
        }
      }

    },
  },
};
</script>
<template>
  <footer id="footer">
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-4">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pe-0">
              <img src="@/assets/images/logoFooter.svg" class="d-inline-block me-5" />
            </div>
            <div class="footer-icons">
              <div>
                <span class="footer-icons-title d-inline-block">Sosial şəbəkələrdə biz:
                </span>
              </div>
              <div class="mt-2 d-flex">
                <a target="_blank" href="https://www.facebook.com/www.mobins.az/" class="footer-icon">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="28" rx="8" fill="#464F61"/>
                    <path d="M15.1386 21V14.6139H17.3901L17.7271 12.1252H15.1386V10.5362C15.1386 9.81563 15.3487 9.32461 16.4341 9.32461L17.8183 9.32399V7.09806C17.5788 7.06778 16.7571 7 15.8012 7C13.8054 7 12.4391 8.1598 12.4391 10.2898V12.1252H10.1819V14.6139H12.4391V20.9999H15.1386V21Z" fill="#E7E8EA"/>
                  </svg>
                </a>
                <a target="_blank" href="https://www.instagram.com/mobins.az/" class="footer-icon">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="28" rx="8" fill="#464F61"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C7 11.2038 7 9.80567 7.66632 8.79865C7.96386 8.34897 8.34897 7.96386 8.79865 7.66632C9.80567 7 11.2038 7 14 7C16.7962 7 18.1943 7 19.2013 7.66632C19.651 7.96386 20.0361 8.34897 20.3337 8.79865C21 9.80567 21 11.2038 21 14C21 16.7962 21 18.1943 20.3337 19.2013C20.0361 19.651 19.651 20.0361 19.2013 20.3337C18.1943 21 16.7962 21 14 21C11.2038 21 9.80567 21 8.79865 20.3337C8.34897 20.0361 7.96386 19.651 7.66632 19.2013C7 18.1943 7 16.7962 7 14ZM17.6239 14.0002C17.6239 16.0016 16.0013 17.6242 13.9999 17.6242C11.9984 17.6242 10.3759 16.0016 10.3759 14.0002C10.3759 11.9987 11.9984 10.3762 13.9999 10.3762C16.0013 10.3762 17.6239 11.9987 17.6239 14.0002ZM13.9999 16.3981C15.3242 16.3981 16.3978 15.3245 16.3978 14.0002C16.3978 12.6759 15.3242 11.6023 13.9999 11.6023C12.6756 11.6023 11.602 12.6759 11.602 14.0002C11.602 15.3245 12.6756 16.3981 13.9999 16.3981ZM17.767 11.0456C18.2373 11.0456 18.6185 10.6644 18.6185 10.1941C18.6185 9.72383 18.2373 9.34259 17.767 9.34259C17.2967 9.34259 16.9155 9.72383 16.9155 10.1941C16.9155 10.6644 17.2967 11.0456 17.767 11.0456Z" fill="#E7E8EA"/>
                  </svg>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/mobins-az/" class="footer-icon">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="28" rx="8" fill="#464F61"/>
                    <path d="M7 8.88207C7 8.43313 7.15766 8.06276 7.47297 7.77096C7.78828 7.47915 8.1982 7.33325 8.7027 7.33325C9.1982 7.33325 9.59909 7.4769 9.90541 7.76423C10.2207 8.06052 10.3784 8.4466 10.3784 8.92248C10.3784 9.35345 10.2252 9.71259 9.91892 9.99992C9.60361 10.2962 9.18919 10.4444 8.67568 10.4444H8.66216C8.16666 10.4444 7.76577 10.2962 7.45946 9.99992C7.15315 9.70362 7 9.331 7 8.88207ZM7.17568 20.6666V11.67H10.1757V20.6666H7.17568ZM11.8378 20.6666H14.8378V15.643C14.8378 15.3288 14.8739 15.0863 14.9459 14.9157C15.0721 14.6105 15.2635 14.3523 15.5203 14.1413C15.777 13.9303 16.0991 13.8248 16.4865 13.8248C17.4955 13.8248 18 14.5027 18 15.8585V20.6666H21V15.5083C21 14.1795 20.6847 13.1716 20.0541 12.4848C19.4234 11.7979 18.5901 11.4545 17.5541 11.4545C16.3919 11.4545 15.4865 11.9528 14.8378 12.9494V12.9763H14.8243L14.8378 12.9494V11.67H11.8378C11.8559 11.9573 11.8649 12.8506 11.8649 14.3501C11.8649 15.8495 11.8559 17.955 11.8378 20.6666Z" fill="#E7E8EA"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <p class="
                    footer-menu-item
                  ">
                  <router-link class="footer-menu-link d-inline-block"  to="/about">
                    Haqqımızda
                  </router-link>
                </p>
                <p class="
                    footer-menu-item
                  ">
                  <router-link class="footer-menu-link d-inline-block"  to="/contact">
                    Bizimlə əlaqə
                  </router-link>
                </p>
                <p class="
                    footer-menu-item
                  ">
                  <router-link class="footer-menu-link d-inline-block"  to="/partnership">
                    Tərəfdaş ol
                  </router-link>
                </p>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <p class="
                    footer-menu-item
                  ">
                  <router-link class="footer-menu-link d-inline-block"  to="/cmtpl">
                    Avto icbari sığorta
                  </router-link>
                </p>
                <p class="
                    footer-menu-item
                  ">
                  <router-link class="footer-menu-link d-inline-block"  to="/travel">
                    Səyahət sığortası
                  </router-link>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 p-0">
            <div class="">
              <div>
                <div class="footer-social-icon d-inline-block">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="8" fill="#313B4F"/>
                    <path d="M29 27V25.3541C29 24.5363 28.5021 23.8008 27.7428 23.4971L25.7086 22.6835C24.7429 22.2971 23.6422 22.7156 23.177 23.646L23 24C23 24 20.5 23.5 18.5 21.5C16.5 19.5 16 17 16 17L16.354 16.823C17.2844 16.3578 17.7029 15.2571 17.3165 14.2914L16.5029 12.2572C16.1992 11.4979 15.4637 11 14.6459 11H13C11.8954 11 11 11.8954 11 13C11 21.8366 18.1634 29 27 29C28.1046 29 29 28.1046 29 27Z" stroke="#00D3D6" stroke-width="1.5" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="footer-contact-title d-inline-block ms-2">Telefon</span>
              </div>
              <div class="footer-menu-item">
                <a target="_blank" href="https://wa.me/+994997007711" class="footer-contact-text footer-menu-link d-inline-block  ps-4 ms-4">
                  (+994 99) 700 77 11
                 </a>
              </div>
            </div>
            <div class="">
              <div>
                <div class="footer-social-icon d-inline-block">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="12" fill="#313B4F"/>
                    <path d="M23 19C23 20.6569 21.6569 22 20 22C18.3431 22 17 20.6569 17 19C17 17.3431 18.3431 16 20 16C21.6569 16 23 17.3431 23 19Z" stroke="#00D3D6" stroke-width="1.5"/>
                    <path d="M29 18.8889C29 23.7981 23.375 30 20 30C16.625 30 11 23.7981 11 18.8889C11 13.9797 15.0294 10 20 10C24.9706 10 29 13.9797 29 18.8889Z" stroke="#00D3D6" stroke-width="1.5"/>
                  </svg>
                </div>
                <span class="footer-contact-title d-inline-block ms-2">Ünvan</span>
              </div>
              <div class="footer-menu-item">
                <a target="_blank"  href="https://goo.gl/maps/R64qBwpNTPWvL1ka6" class="footer-contact-text footer-menu-link d-inline-block  ps-4 ms-4">
                  Bakı şəh., Hacı Murad küç. 1/6, Badamar Plaza, AZ 1075
                </a>
              </div>
            </div>
            <div class="">
              <div>
                <div  class="footer-social-icon d-inline-block">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="12" fill="#313B4F"/>
                    <path d="M14 16L17.7812 18.5208C19.1248 19.4165 20.8752 19.4165 22.2188 18.5208L26 16M14 29H26C28.2091 29 30 27.2091 30 25V15C30 12.7909 28.2091 11 26 11H14C11.7909 11 10 12.7909 10 15V25C10 27.2091 11.7909 29 14 29Z" stroke="#00D3D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="footer-contact-title d-inline-block ms-2">E-poçt</span>
              </div>
              <div class="footer-menu-item">
                <a target="_blank"  href = "mailto: info@mobins.az" class="footer-contact-text footer-menu-link d-inline-block  ps-4 ms-4">
                  info@mobins.az
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<template>
  <div class="container-fluid mb-5 pb-5">
    <div class="row pb-4">
      <nav class="
          navbar navbar-expand-lg navbar-light
          fixed-top
          bg-white
          pt-0
          pb-3
          d-inline-flex
          app-navbar
        ">
        <div class="container app-nav-container">
          <router-link to="/" class="nav-logo">
            <img src="@/assets/images/main-logo.svg"
              class="img-responsive img-logo font-weight-bold d-inline-block pt-4" />
          </router-link>

          <div class="d-flex align-items-center nav-mobile-icons mt-4">
            <router-link to="/payment">
              <button class="contract-toggler bg-transparent border-0 px-1" >
                <img src="@/assets/images/card.svg"  />
              </button>
            </router-link>

            <button v-if="!authenticated" class="contract-toggler bg-transparent border-0 px-1" type="button" @click="login">
              <img src="@/assets/images/mobile-avatar.svg"  />
            </button>

            <button v-if="authenticated" class="navbar-toggler profil-navbar-toggler border-0 px-1" type="button"
              data-bs-toggle="collapse" data-bs-target="#wc-nav" @click="goProfileIfNotNav">
              <img src="@/assets/images/mobile-avatar.svg"  />
            </button>

            <button class="navbar-toggler profil-navbar-toggler px-1" data-bs-toggle="collapse"
              data-bs-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <img src="@/assets/images/mobile-menu.svg" />
            </button>
          </div>

          <div class="navbar-collapse collapse text-left" id="nav">
            <ul class="navbar-nav">
              <a href="/" class="navbar-brand d-lg-none">
                <img src="@/assets/images/app-logo.svg" class="img-responsive font-weight-bold d-inline-block pt-4" />
              </a>
              <li class="nav-item">
                <router-link to="/cmtpl" active-class="nav-link-active" class="
                    nav-link
                    d-inline-block
                    nav-link-hover
                    pt-5
                    pb-4
                    me-3
                    cursor-pointer
                  ">
                  Avto İcbari
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/travel" active-class="nav-link-active" class="
                    nav-link
                    d-inline-block
                    nav-link-hover
                    pt-5
                    pb-4
                    me-3
                    cursor-pointer
                  ">
                  Səfər sığortası
                </router-link>
              </li>
              <li class="nav-item partner-nav-item">
                <router-link to="/partnership" :class="{ active: isActive('/partnership') }" active-class="nav-link-active" class="
                    nav-link
                    d-inline-block
                    nav-link-hover
                    pt-5
                    pb-4
                    me-3
                    cursor-pointer
                  ">
                  Tərəfdaşlıq
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/contact" active-class="nav-link-active" class="
                    nav-link
                    d-inline-block
                    nav-link-hover
                    pt-5
                    pb-4
                    me-3
                    cursor-pointer
                  ">
                  Əlaqə
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about" active-class="nav-link-active" class="
                    nav-link
                    d-inline-block
                    nav-link-hover
                    pt-5
                    pb-4
                    me-3
                    cursor-pointer
                  ">
                  Haqqımızda
                </router-link>
              </li>
            </ul>
            <div class="ms-auto nav-buttons d-flex align-items-center mt-2">
              <router-link to="/payment"  class="
                  btn  btn-default btn-hover
                  d-inline-block
                  text-decoration-none
                  ">
                  Ödəniş et
              </router-link>
              <a v-if="!authenticated" class="
                  btn-main btn-default btn-hover
                  cursor-pointer
                  d-inline-block
                  ms-3
                " @click.prevent="login">
                Daxil ol
              </a>

              <a v-if="authenticated" class="text-decoration-none cursor-pointer ms-3">
                <div class="dropdown">
                  <div class=" dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="@/assets/images/nav-user.svg" class=" ms-1" />
                  </div>
                  <ul class="dropdown-menu user-dropdown-menu mt-2" aria-labelledby="dropdownMenuButton1">
                    <li class="dropdown-item dropdown-menu-item" :class="{ active: isActive('/profile') }"  @click.prevent="profile">
                      <img src="@/assets/images/user-circle.svg" />
                      <a class="ms-2 profile-dropdown-text">Şəxsi Kabinet</a>
                    </li>
                    <li class="dropdown-item dropdown-menu-item" @click.prevent="logout">
                      <img src="@/assets/images/logout.svg" />
                      <a class="ms-2 profile-dropdown-text" >Çıxış et</a>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <login-modal />
</template>

<script>
import authService from "@/services/auth.service";
import LoginModal from "../account/LoginModal.vue"

export default {
  components: { LoginModal },
  computed: {
    authenticated() {
      return authService.isAuthenticated();
    },

    accountName() {
      return localStorage.getItem("account_name");
    },
    data() {
    return {
      active: false 
    };
  }

  },
  methods: {
    login() {
      this.emitter.emit("showLoginModal");
    },

    logout() {
      authService.logout();
      location.href = "/";
    },

    profile() {
      this.$router.push("/profile");
    },

    isActive(route) {
      return this.$route.path.startsWith(route);
    },

    goProfileIfNotNav() {
      if (!this.$route.path.startsWith("/profile")) {
        this.$router.push("/profile");
      }
    },
  },
};
</script>
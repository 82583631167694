<template>
  <div class="app-modal-body widget-tracker widget-tracker-active">
    <div class="app-modal-header border-0">
      <p class="modal-title h2 text-main fw-bold">Daxil ol</p>
      <p class="modal-subtitle mt-0">
        Qeydiyyatdan keçməyə ehtiyac yoxdur, sizin şəxsi kabinetiniz sığorta
        aldığınız zaman avtomatik açılır.
      </p>
      <button
        type="button"
        class="app-modal-close"
        data-bs-dismiss="modal"
      ></button>
    </div>
    <form @submit.prevent="submitHandler">
      <div class="form-group position-relative">
        <div
          class="form-group mt-4 position-relative"
          :class="{ error: v$.form.username.$errors.length }"
        >
        <label  class="label-pswd">Mobil nömrə</label>
          <input
            v-model="form.username"
            v-maska="'+994## ###-##-##'"
            placeholder="+994 ( __ ) ___ __ __"
            type="text"
            class="form-control login-input"
          />
        </div>
        <div
          class="form-group mt-3 position-relative"
          :class="{ error: v$.form.password.$errors.length }"
        >
          <password-input title="Şifrə"  v-model:inputData="form.password" />
        </div>
        <div class="form-group mt-3 text-end">
          <button
            type="button"
            class="
              text-main
              text-decoration-none
              forgot-password
              border-0
              bg-white
            "
            @click="forgotPassword"
          >
          Şifrəni unutmuşam
          </button>
        </div>

        <button
          type="submit"
          class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100 modal-btn"
          :disabled="isLoading"
        >
          <span class="spinner-border spinner-border-sm" v-if="isLoading" />
          Daxil ol
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import api from "@/api/account.api";

const initialValue = {
  username: null,
  password: null,
};

const validations = {
  username: { required },
  password: { required },
};

export default {
  directives: { maska },
  data: () => ({
    isLoading: false,
    form: { ...initialValue },
    phoneNumber:null
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    forgotPassword() {
      this.$emit("forgot-password");
    },

    async submitHandler() {
      const result = await this.v$.$validate();
      if (result) {
        this.isLoading = true;

        const { username, password } = this.form;
        api
          .login(username, password)
          .then((data) => {
            const { token } = data;
            const { isPartner, fullName, phoneNumber } = data.user;

            if (isPartner) {
              location.href = process.env.VUE_APP_AGENT_URL;
            } else {
              localStorage.setItem("access_token", token);
              localStorage.setItem("account_name", fullName);
              localStorage.setItem("account_phoneNumber", phoneNumber);

              location.href = "/profile";
            }
          })
          .catch((error) => this.emitter.emit("error", error))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>
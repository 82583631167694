<template>
  <div class="widget-tracker widget-tracker-active" id="password-widget-tracker">
    <div class="app-modal-header border-0">
      <p class="modal-title h2 text-main fw-bold">OTP kodu daxil edin</p>
      <p class="otp-again-text mt-0 mb-4">
        Zəhmət olmasa, mobil nömrənizə göndərilən
        4 rəqəmli kodu daxil edin.
      </p>
      <button type="button" class="app-modal-close" data-bs-dismiss="modal"></button>
    </div>
    <form @submit.prevent="submitHandler">

      <p class="otp-error text-center">{{ errorMessage }}</p>

      <OtpPad :otp="otp" />

      <p class="otp-again-text text-center mt-4">
        Kod gəlmədi? <span class="ms-1" @click.stop="againSubmit">
          Yenidən göndər
        </span>
      </p>

      <button :disabled="isLoading" type="submit"
        class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100 modal-btn">
        <span class="spinner-border spinner-border-sm" v-if="isLoading" />
        Təsdiqlə
      </button>
    </form>

  </div>
</template>
  
<script>
import OtpPad from "./OtpPad.vue";

export default {
  components: { OtpPad },
  props: ["number", "otpId","errorMessage","loader"],
  data: () => ({
    otp: ['', '', '', ''],
    errorMessage: null,
    isLoading:false
  }),
  methods: {
    submitHandler() {
      const phoneNumber = this.number;
      const otpId = this.otpId;
      const otpCode = this.otp.join('');

      this.$emit("verifyOtp", otpId, phoneNumber,otpCode);
    },
    
    againSubmit() {
      this.isLoading=this.loader;
      const phoneNumber = this.number;
      this.$emit("forgotPasswordMethod", phoneNumber);
    },
  }
};
</script>
  
<template>
  <div class="widget-tracker widget-tracker-active" id="password-widget-tracker">
    <div class="app-modal-header border-0">
      <p class="modal-title h2 text-main fw-bold">Şifrəni yeniləyin</p>
      <p class="modal-subtitle mt-0">
        Mobil nömrənizə göndərdiyimiz kod 
        vasitəsilə yeni şifrə təyin edə biləcəksiniz.
      </p>
      <button type="button" class="app-modal-close" data-bs-dismiss="modal" @click="hide"></button>
    </div>
    <form @submit.prevent="forgotPassword">
      <div class="form-group mt-4 position-relative" :class="{ error: v$.form.phoneNumber.$errors.length }">
        <label class="label-pswd">Mobil nömrə</label>
        <input v-maska="'+994## ###-##-##'" v-model="form.phoneNumber" placeholder="+994 ( __ ) ___ __ __" type="text"
          class="form-control login-input" />
      </div>

      <button :disabled="isLoading" type="submit" class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100 modal-btn">
        <span class="spinner-border spinner-border-sm" v-if="isLoading" />
        Göndər
      </button>

      <a type="button" class="forgot-pass-url  text-center w-100 pt-3
        " @click="login">
        <span class="text-blue">Geri</span> qayıdın
      </a>
    </form>
  </div>
</template>

<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";


const initialValue = {
  phoneNumber: null,
};

const validations = {
  phoneNumber: { required },
};

export default {
  props: ["isLoading"],
  directives: { maska },
  data: () => ({
    form: { ...initialValue },
    otpId: null,
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    login() {
      this.$emit("login");
    },
    forgotPassword() {
      const result =  this.v$.$validate();
      if (result) {
        this.$emit("forgotPasswordMethod", this.form.phoneNumber);
      }
    },
    hide() {
      this.$emit("hide");
    },
  }
};
</script>

